@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap";

@font-face {
    font-family: "din-next";
    src: url(./fonts/din.ttf);
    font-display: swap
}

:root {
    --main-header: 30px;
    --secondary-header: 22px;
    --secondary-header: 22px;
    --main-color: #fb5607;
    --seconday-color: #ffba0b
}

body {
    margin: 0;
    font-family: "Poppins", "din-next", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f5f7fa
}

.ar {
    direction: rtl;
    text-align: right
}

section {
    padding: 80px 0
}

.main-heading {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-shadow: 3px 0 0 rgba(0, 0, 0, 0.2)
}

.section-heading {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 450px;
    margin: auto
}

.section-heading h2 {
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 5px;
    background: -webkit-linear-gradient(180deg, #fb5607, #ffba0b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.section-heading::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 35%;
    height: 2px;
    background: -webkit-linear-gradient(180deg, #fb5607, #ffba0b)
}

.ar .section-heading h2 {
    background: -webkit-linear-gradient(0deg, #fb5607, #ffba0b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.ar .section-heading::before {
    right: 0;
    background: -webkit-linear-gradient(0deg, #fb5607, #ffba0b)
}

@media (max-width: 767.98px) {
    .section-heading h2 {
        font-weight: 600;
        font-size: 25px
    }
}

.paragraph {
    color: #333;
    font-size: 16px;
    line-height: 30px;
}

.icon {
    color: #092c4c;
    font-size: 25px
}

.sm-icon:hover {
    color: var(--main-color)
}

header {
    height: 90px;
    background-color: #fb5607;
    z-index: 50
}

.main {
    padding: 0;
    background: #ffba0b;
    background: linear-gradient(0deg, rgba(255, 186, 11, 1) 0%, rgba(254, 161, 10, 1) 9%, rgba(253, 124, 9, 1) 60%, rgba(251, 86, 7, 1) 100%);
    min-height: 700px
}

.main .container-fluid {
    max-width: 1700px
}

.main .row {
    min-height: 570px
}

.main .paragraph {
    font-size: 18px;
    line-height: 30px;
    color: #fff
}

.ar .main .paragraph {
    width: 100%
}

.main .tutipay-btn a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    padding: 10px 20px;
    border-radius: 8px
}

.main .tutipay-btn a:hover {
    background-color: #fff;
    color: var(--main-color)
}

@media (max-width: 991.98px) {
    .main-heading {
        font-size: 35px
    }

    .section-heading {
        font-size: 50px
    }

    .main .paragraph {
        margin: auto;
        margin-bottom: 20px
    }

    .main .tutipay-btn {
        margin: auto
    }

    .app-img img {
        margin: auto;
        max-width: 500px
    }
}

@media (max-width: 767.98px) {
    .main-heading {
        font-size: 30px
    }

    .section-heading {
        font-size: 30px
    }

    .main .paragraph {
        font-size: 16px;
        line-height: 20px
    }

    .screens-slider {
        max-width: 350px;
    }
}

.screens-slider {
    position: relative;
    width: 100%;
    margin: auto;
}

.screens-slider img {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    object-fit: contain;
    transform: translate(-50%, -40%)
}

.mockup1,
.mockup2,
.mockup3,
.mockup4,
.mockup5,
.mockup6 {
    animation-duration: 10s;
    animation-iteration-count: infinite
}

.mockup1 {
    animation-name: firstScreen
}

.mockup2 {
    animation-name: secondScreen
}

.mockup3 {
    animation-name: thirdScreen
}

.mockup4 {
    animation-name: fourthScreen
}

.mockup5 {
    animation-name: fifthScreen
}

.mockup6 {
    animation-name: sixthScreen
}

@keyframes firstScreen {
    0% {
        opacity: 1;
        display: block
    }

    16.7% {
        opacity: 0;
        display: none
    }

    100% {
        opacity: 1;
        display: block
    }
}

@keyframes secondScreen {
    0% {
        opacity: 0;
        display: none
    }

    16.7% {
        opacity: 1;
        display: block
    }

    33.3% {
        opacity: 0;
        display: none
    }

    100% {
        opacity: 0;
        display: none
    }
}

@keyframes thirdScreen {
    0% {
        opacity: 0;
        display: none
    }

    16.7% {
        opacity: 0;
        display: none
    }

    33.3% {
        opacity: 1;
        display: block
    }

    50% {
        opacity: 0;
        display: none
    }

    100% {
        opacity: 0;
        display: none
    }
}

@keyframes fourthScreen {
    0% {
        opacity: 0;
        display: none
    }

    33.3% {
        opacity: 0;
        display: none
    }

    50% {
        opacity: 1;
        display: block
    }

    66.7% {
        opacity: 0;
        display: none
    }

    100% {
        opacity: 0;
        display: none
    }
}

@keyframes fifthScreen {
    0% {
        opacity: 0;
        display: none
    }

    50% {
        opacity: 0;
        display: none
    }

    66.7% {
        opacity: 1;
        display: block
    }

    83.3% {
        opacity: 0;
        display: none
    }

    100% {
        opacity: 0;
        display: none
    }
}

@keyframes sixthScreen {
    0% {
        opacity: 0;
        display: none
    }

    66.7% {
        opacity: 0;
        display: none
    }

    83.3% {
        opacity: 1;
        display: block
    }

    100% {
        opacity: 0;
        display: none
    }
}

.whyus-section {
    background-color: #f5f7fa
}

.whyus-section .service-card {
    position: relative;
    min-height: 240px;
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: all .3s ease !important;
    -moz-transition: all .3s ease !important;
    -ms-transition: all .3s ease !important;
    -o-transition: all .3s ease !important;
    transition: all .3s ease !important
}

.whyus-section .service-card::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 10px;
    width: 10px;
    background-color: rgba(255, 255, 255, 1);
    z-index: -1;
    -webkit-transition: all .5s ease !important;
    -moz-transition: all .5s ease !important;
    -ms-transition: all .5s ease !important;
    -o-transition: all .5s ease !important;
    transition: all .5s ease !important
}

.whyus-section .service-card .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #092c4c;
    -webkit-transition: all .5s ease !important;
    -moz-transition: all .5s ease !important;
    -ms-transition: all .5s ease !important;
    -o-transition: all .5s ease !important;
    transition: all .5s ease !important
}

.whyus-section .service-card p,
.whyus-section .service-card .card-icon svg {
    -webkit-transition: all .5s ease !important;
    -moz-transition: all .5s ease !important;
    -ms-transition: all .5s ease !important;
    -o-transition: all .5s ease !important;
    transition: all .5s ease !important
}

.whyus-section .service-card:hover::before {
    transform: scale(100)
}

.whyus-section .service-card .card-icon {
    position: relative;
    width: 200px;
    height: 100px;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 10px;
    z-index: 1
}

.whyus-section .service-card .bg-icon {
    position: absolute;
    left: 20px;
    bottom: 0;
    width: 140px;
    fill: #f5f7fa;
    z-index: -1
}

.whyus-section .service-card:hover .card-icon svg {
    fill: var(--main-color) !important
}

.transactions-section .container {
    position: relative;
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    z-index: 1
}

.separator {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) !important;
    z-index: -1;
    display: none
}

.transactions-section .st0 {
    fill: #f0f0f0
}

.transactions-section .st1 {
    fill: #fff
}

.agency-section .container {
    position: relative;
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    z-index: 1
}

.agency-section .st0 {
    fill: #f0f0f0
}

.agency-section .st1 {
    fill: #fff
}

.bannar-section .container {
    position: relative;
    background: linear-gradient(0deg, #fff 50%, #fff 50%);
    padding: 40px 20px;
    border-radius: 8px;
    z-index: 1
}

@media (max-width: 1200px) {
    .agency-section .container {
        min-height: auto
    }
}

.bannar-section .st0 {
    fill: #f0f0f0
}

.contactus-section .section-heading {
    max-width: 600px
}

.bannar-section .st1 {
    fill: #fff
}

.bannar-section img {
    max-width: 550px
}

.joinus-section {
    background-color: rgba(255, 255, 255)
}

.logo,
.joinus-section img,
.agency-section img,
.transactions-section img,
.bannar-section img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

@media (max-width: 767.98px) {

    .joinus-section img,
    .agency-section img,
    .transactions-section img,
    .bannar-section img {
        height: auto;
        margin-bottom: 20px
    }
}

.contactus-section {
    background: linear-gradient(90deg, rgba(9, 44, 76, 0.8) 100%, #000 0%), url(./images/contact-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #092c4c
}

.contactus-section .section-heading:before {
    left: 50%;
    transform: translateX(-50%)
}

.ar .contactus-section .section-heading:before {
    transform: translateX(-100%)
}

.contactus-section input {
    border: 0;
    max-width: 250px;
    width: 100%;
    outline: none;
    background-color: #fff;
    font-size: 15px;
    border-radius: 4px
}

.contactus-section .form-control {
    height: 40px !important;
    font-family: "Poppins", "din-next", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px !important;
    border: 0 !important;
    box-shadow: none;
    border-radius: 0 !important;
    width: 100%
}

.react-tel-input .form-control {
    border-radius: 4px !important
}

.ar .react-tel-input {
    direction: ltr
}

.react-tel-input .country-list .country-name {
    font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    font-weight: 500
}

.react-tel-input .country-list .dial-code {
    font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif
}

.contactus-section .btn {
    border-radius: 0;
    border: 0;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 4px;
    max-width: 250px;
    width: 100%
}

.contactus-section .btn:hover {
    background-color: #fff;
    color: var(--main-color)
}

@media (max-width: 575.98px) {

    .contactus-section input,
    .contactus-section .btn {
        max-width: 300px
    }
}

#pageMessages {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 30%
}

.alert {
    position: fixed;
    top: 60px;
    width: 100%;
    max-width: 500px;
    border-radius: 0;
    z-index: 100;
    transition: all .5s ease-in-out;
    animation: animated 2s forwards ease-in-out
}

@keyframes animated {
    from {
        left: -200px
    }

    to {
        left: 0
    }
}

.alert .btn-close {
    color: #fff !important
}

.alert-success {
    background-color: #64b365;
    border-color: #4b9b5b;
    color: #fff
}

.alert-danger {
    background-color: #ec4c4c;
    border: none;
    color: #fff
}

footer {
    background-color: #fff;
    border-top: 1px solid #ddd;
}

footer .upper-footer {
    padding: 40px 0 0 0;
}

footer .footer-header {
    position: relative;
    display: inline-block;
    color: #092c4c;
    font-weight: 500
}

footer ul {
    list-style: none;
    padding: 0
}

footer ul li {
    margin-bottom: 10px
}

footer p {
    font-weight: 400 !important
}

footer .made-in-sudan {
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 0;
    padding: 0 15px;
}

@media (max-width: 767.98px) {
    footer {
        text-align: center;
    }

    footer .phone-number {
        justify-content: center;
    }
}

footer .copy-right .paragraph {
    color: #fff;
}

footer .copy-right {
    background: #092c4c;
}

footer .morelinks {
    border-top: 1px solid #ddd; 
    padding: 10px 0;
}

footer .morelinks a {
    color: #333;
    text-decoration: none;
}
footer .morelinks a:hover{
    text-decoration: underline;
}

/* Privacy Policy  */
.privacy-policy,
.terms-of-use {
    background-color: #f7f7f7;
}

.privacy-policy h3,
.terms-of-use h3 {
    font-size: 22px;
    font-weight: 600;
    background: -webkit-linear-gradient(0deg, #fb5607, #ffba0b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.privacy-policy h5,
.terms-of-use h5 {
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 767.98px) {

    .privacy-policy h3,
    .terms-of-use h3 {
        font-size: 20px;
    }
}